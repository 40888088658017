import React from 'react'
import { Container } from 'react-bootstrap';
import './Footer.css';

const Footer = () => {
    return (
        <div className='footer_main'>
            <div className='footer_div'>
                <img src='/images/main_logo.png' alt='' />

                <div className='footer_links'>
                    <a href='/#'>Home</a>
                    <a href='/#about'>About</a>
                    <a href='/#cast'>Cast</a>
                    <a href='/#crew'>Crew</a>
                    <a href='/#media'>Media Partners</a>
                </div>

                <div className='footer_icon'>
                    <a href='https://www.facebook.com/dhaichaalfilm/' target='_blank'><img src='/images/fb_icon.png' alt='' /> </a>
                    <a href='https://www.instagram.com/dhaichaal/?hl=en' target='_blank'><img src='/images/insta_icon.png' alt='' /></a>
                    <a href='https://twitter.com/dhaai_chaal' target='_blank'><img src='/images/twitter_icon.png' alt='' /></a>
                </div>
            </div>

            <div className='footer_reserved'>
                <p>
                    © 2023 All Rights Reserved.
                </p>
            </div>
        </div>
    )
}

export default Footer