import React from 'react'
import { Container } from 'react-bootstrap';
import './TopBanner.css';

const TopBanner = () => {
    return (
        <Container>
            <div className='top_banner_main'>
                <img src='/images/main_banner.jpg' alt='' />

                <div className='text-center mt-4'>
                    <a href='/#header'>Get Started</a>
                </div>
            </div>
        </Container>
    )
}

export default TopBanner