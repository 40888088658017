import React from 'react';
import { Container } from 'react-bootstrap';
import ReactPlayer from 'react-player';
import Trailer1 from '../../assets/trailer.mp4';
import './Trailer.css';

const Trailer = () => {
    return (
        <Container>
            <div className='main_trailer' id='trailer'>
                <h1>MOVIE </h1>
                <h2>TRAILER</h2>

                <div className='player_main'>
                    {/* <ReactPlayer url={Trailer1} playing={false} controls={true}
                        className='player_div'
                    /> */}

                    <iframe style={{ border: "0", overflow: "hidden", maxWidth: "950px", maxHeight: "450px", }} src="https://www.youtube.com/embed/thm9OugMpqQ?si=Y6Qk2l2EAiV4SEze"
                        title="YouTube video player" frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen></iframe>
                </div>

                <div className='scroll_top'>
                    <a href='/#production'><img src='/images/scroll_bottom.png' alt='' /></a>
                </div>
            </div>
        </Container>
    )
}

export default Trailer