import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './MediaPartner.css';

const MediaPartner = () => {
    return (
        <div className='media_partner my-5' id='media'>
            <h1><span>MEDIA</span> PARTNERS</h1>

            <Container>
                <Row className='justify-content-center media_row'>
                    <Col md={8}>
                        <Row>
                            <Col md={4}>
                                <img src='/images/HUM_Films_logo.png' />
                            </Col>
                            <Col md={4}>
                                <img src='/images/HUM_News_Logo.png' />
                            </Col>
                            <Col md={4}>
                                <img src='/images/hum_urdu_logo.png' style={{height: "200px", objectFit: "contain"}} />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default MediaPartner