import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './Producer.css';

const Producer = () => {
    return (
        <Container>
            <div className='producer_main'>
                <Row>
                    <Col md={6}>
                        <div>
                            <img src='/images/producer.png' />
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className='producer_text'>
                            <h1>PRODUCER</h1>
                            <h3>DR IRFAN ASHRAF</h3>
                            <p>
                                Dr Irfan Ashraf is the producer of film Dhaichaal his education is from Balochistan and has Depth
                                insight on Happenings in Balochistan.Dr Irfan is a TV Anchor/ Analyst by profession.
                                He was Awarded Dagger of honour for his work in war of narrative in Balochistan.
                                He is writing Articles for Turkish leading English Newspaper Daily Sabah. <br />
                                He served as Director General Kashmir cultural Academy from December 2021-April 2022. <br />
                                Dr Irfan is certified by National security workshop.
                            </p>
                        </div>
                    </Col>
                </Row>
            </div>

            <div className='producer_main'>
                <Row className='make_reverse'>
                    <Col md={6}>
                        <div className='producer_text'>
                            <h1>DIRECTOR</h1>
                            <h3>TAIMOOR SHERAZI</h3>
                            <p>
                                Taimoor sherazi is an aspiring film director with a firm grip on the whole creative process
                                of portraying his vision visually in films. He started his career early and decided to get
                                hands on experience and became responsible for some of the well known film projects in our industry.
                                He started his career with the film "Destruction". His scope isn't just limited to the local
                                industry and includes some international projects like "Do Over" (USA DVD release).
                                "Ae dil mere chal re" and "The Rebels" are among the film projects he worked on.
                            </p>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className='taimoor_director'>
                            <img src='/images/taimoor.jpg' />
                        </div>
                    </Col>
                </Row>
            </div>

            <div className='producer_main'>
                <Row className='align-items-center'>
                    <Col md={6}>
                        <div className='writer_main'>
                            <img src='/images/writer2.png' />
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className='producer_text'>
                            <h1>WRITER</h1>
                            <h3>FARHEEN CHAUDHRY</h3>
                            <p>
                                Farheen Chaudhry is an established Pakistani screenwriter for the last 30 years. Her contributions for Urdu literature are commendable not only as a writer but as a promoter of literature and as founder/ chairperson of Literary Arts and Cultural Syndicate. She has received national and international fame due to her work. She has represented Pakistan as head delegate on SAARC’s writers, literary, cultural and Sufi conferences for peace numerous times. She has also served Lok Virsa as Programme Executive and later as Director Productions at Manhattan International Karachi. She worked for Evernew Concept Islamabad and as Station Head of PUNJAB TV and STAR ASIA.
                            </p>
                        </div>
                    </Col>
                </Row>
            </div>

            {/* <div className='producer_main border_bottom inside_box'>
                <Row className=''>
                    <Col md={6}>
                        <div className='text-center asst_prod'>
                            <img src='/images/asst_producer.png' />
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className='producer_text'>
                            <h1>ASSOCIATE</h1>
                            <h2>PRODUCER</h2>
                            <h3 className='mt-4'>FAJJAR MINHAS</h3>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                            </p>
                        </div>
                    </Col>
                </Row>
            </div> */}
        </Container>
    )
}

export default Producer