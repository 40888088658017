import React from 'react';
import { Navbar, Container, Nav } from 'react-bootstrap';
import './Navbar.css';

const Header = () => {
    return (
        <Navbar expand="lg" id='header'>
            <Container>
                <Navbar.Brand href="/"><img src='/images/main_logo.png' /></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ms-auto">
                        <Nav.Link href="/">Home</Nav.Link>
                        <Nav.Link href="/#about">About</Nav.Link>
                        <Nav.Link href="/#cast">Cast</Nav.Link>
                        <Nav.Link href="/#cast">Crew</Nav.Link>
                        <Nav.Link href="/#activities">Promotional Activities</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default Header