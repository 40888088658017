import React from 'react'
import { Container } from 'react-bootstrap'

const AnimatedTeaser = () => {
    return (
        <Container>
            <div className='main_trailer'>
                <h1>ANIMATED</h1>
                <h2>TEASER</h2>
            </div>

            <div className='teaser_iframe'>
            <iframe style={{ border: "0", overflow: "hidden", maxWidth: "950px", maxHeight: "450px" }} src="https://www.youtube.com/embed/0eInXQCmh2s?si=5BvCyZ4SKFEOrS0h" 
            title="YouTube video player" frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            allowfullscreen></iframe>

                {/* <iframe src="https://youtu.be/0eInXQCmh2s?si=5BvCyZ4SKFEOrS0h"
                    style={{ border: "0", overflow: "hidden", maxWidth: "950px", maxHeight: "450px" }} scrolling="no" frameborder="0" allowfullscreen="true"
                    allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                    allowFullScreen="true"></iframe> */}
            </div>
        </Container>
    )
}

export default AnimatedTeaser