import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import './NewRelease.css';

const NewRelease = () => {

    return (
        <div>
            <Container>
                <Row className='align-items-center'>
                    <Col md={5}>
                        <div className='release_text'>
                            <h1>NEW RELEASE <span>TRAILER</span></h1>
                            <p>Watch now the Trailer of Dhaichaal.</p>

                            <div className='watch_now'>
                                <h6>WATCH NOW</h6>
                                <a href='/#trailer'><img src='/images/play_btn.png' /></a>
                            </div>
                        </div>
                    </Col>
                    <Col md={7}>
                        <div>
                            <img src='/images/release_right.png' className='release_img' />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default NewRelease