import React from 'react';
import { Container } from 'react-bootstrap';
import ReactPlayer from 'react-player';
import Song1 from '../../assets/song1.mp4';
import './Songs.css';

const Songs = () => {
    return (
        <Container>
            <div className='main_trailer'>
                <h1>OUR RELEASE</h1>
                <h2>SONG</h2>

                <h4>- NIKLO PAKISTAN</h4>
                <div className='player_main'>
                    <ReactPlayer url={Song1} playing={false} controls={true}
                        className='player_div'
                        width="1000px" height="500px" style={{ border: "1px solid" }}
                    />
                </div>
            </div>
        </Container>
    )
}

export default Songs