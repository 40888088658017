import React from "react";
import About from "./Components/About/About";
import Activities from "./Components/Activities/Activities";
import Crew from "./Components/Crews/Crew";
import Footer from "./Components/Footer/Footer";
import MediaPartner from "./Components/MediaPartner/MediaPartner";
import Navbar from "./Components/Navbar/Navbar";
import NewRelease from "./Components/NewRelease/NewRelease";
import OurStar from "./Components/OurStar/OurStar";
import Producer from "./Components/Producer/Producer";
import Production from "./Components/Production/Production";
import Songs from "./Components/Songs/Songs";
import TopBanner from "./Components/TopBanner/TopBanner";
import Trailer from "./Components/Trailer/Trailer";
import Vision from "./Components/Vision/Vision";
import AnimatedTeaser from "./Components/AnimatedTeaser/AnimatedTeaser";

function App() {
  return (
    <div className="App">
      <TopBanner />
      <Navbar />
      <Trailer />
      <Production />
      <About />
      <Vision />
      <AnimatedTeaser />
      <Producer />
      <MediaPartner />
      <Songs />
      <OurStar />
      <Crew />
      <Activities />
      <NewRelease />
      <Footer />
    </div>
  );
}

export default App;
