import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import ActivityVideo from '../../assets/video-activity.mp4';
import ReactPlayer from 'react-player';
import './Activities.css'

const Activities = () => {
    return (
        <div className='activities_main' id='activities'>
            <h1>PROMOTIONAL </h1>
            <h2>ACTIVITIES</h2>

            <div>
                <Container>
                    <Row className='gy-3'>
                        <Col md={4}>
                            <div className='activity_box'>
                                <img src='/images/activity1.png' />
                                <p>Film Dhai Chaal’s track ‘Niklo Pakistan Ki Khatir’ released</p>

                                <div className='text-center my-3'>
                                    <a href='https://www.radio.gov.pk/08-09-2022/film-dhai-chaal-releases-song-to-pays-tribute-to-unsung-heroes-of-balochistanpakistan'
                                        target='_blank'>Read More</a>
                                </div>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className='activity_box'>
                                <img src='/images/activity3.png' />
                                <p>Dhai Chaal to showcase indelible sacrifices against terrorism</p>

                                <div className='text-center my-3'>
                                    <a href='https://www.app.com.pk/features/dhai-chaal-to-showcase-indelible-sacrifices-against-terrorism/'
                                        target='_blank'>Read More</a>
                                </div>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className='activity_box'>
                                <img src='/images/activity4.webp' />
                                <p>Pakistani film Dhai Chaal, strongly condemned the Indian film writer Javed Akhtar’s involvement in the Mumbai attacks</p>

                                <div className='text-center my-3'>
                                    <a href='https://www.dailyparliamenttimes.com/2023/02/25/pakistani-film-dhai-chaal-strongly-condemned-the-indian-film-writer-javed-akhtars-involvement-in-the-mumbai-attacks/'
                                        target='_blank'>Read More</a>
                                </div>
                            </div>
                        </Col>

                        <Col md={4}>
                            <div className='activity_box'>
                                <img src='/images/activity5.png' />
                                <p>'Dhai Chaal' Movie To Reveal Unheard Story Of Balochistan's Sacrifices Against Terrorism</p>

                                <div className='text-center my-3'>
                                    <a href='https://www.urdupoint.com/en/showbiz/dhai-chaal-movie-to-reveal-unheard-story-of-1656859.html'
                                        target='_blank'>Read More</a>
                                </div>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className='activity_box'>
                                <img src='/images/activity6.png' style={{ objectFit: "unset" }} />
                                <p>Dhai Chaal to showcase indelible sacrifices against terrorism</p>

                                <div className='text-center my-3'>
                                    <a href='https://dailytimes.com.pk/1067068/dhai-chaal-to-showcase-indelible-sacrifices-against-terrorism/'
                                        target='_blank'>Read More</a>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={9}>
                            <div className='player_main extra_marging'>
                                <ReactPlayer url={ActivityVideo} playing={false} controls={true}
                                    className='player_div'
                                    width="1000px" height="500px" style={{ border: "1px solid" }}
                                />
                            </div>
                        </Col>
                        <Col md={3}>
                            <div>
                                <img src='/images/activity1.jpg' />
                            </div>
                        </Col>
                        {/* <Col md={3}>
                            <img src='/images/activity2.jpg' />
                        </Col> */}
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default Activities