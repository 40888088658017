import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import './About.css';

const About = () => {
    return (
        <Container>
            <div className='main_about' id='about'>
                <Row className='align-items-center'>
                    <Col md={6}>
                        <div>
                            <h1><span>OBJECTIVE</span></h1>
                            <ul>
                                <li>It's an effort to highlight true story of pakistan</li>
                                <li>To pay tribute to the sacrifices of locals of Balochistan</li>
                                <li>To promote positive impact of CPEC and to counter misinformation about Balochistan</li>
                                <li>To expose the Indian sponsored terrorism in Balochistan</li>
                                <li>To highlight the tourism potential of Balochistan </li>
                                <li>To expose the Indian sponsored terrorism i.e Spy Kulbhushan Yadav network</li>
                                <li>To show the reality of Pakistan to the World in soft way</li>
                            </ul>
                        </div>
                    </Col>
                    <Col md={6}>
                        <img src='/images/about_img.png' className='about_img' />
                    </Col>
                </Row>
            </div>
        </Container>
    )
}

export default About