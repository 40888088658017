import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './Production.css';

const Production = () => {
    return (
        <div className='main_trailer' id='production'>
            <h1>DURING FILM </h1>
            <h2>PRODUCTION</h2>

            <div className='production_main'>
                <Container>
                    <Row>
                        <Col md={4}>
                            <div>
                                <img src='/images/prod_left.png' />
                                {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                </p> */}
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className='center_prod'>
                                <img src='/images/prod_center.png' />
                                {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                </p> */}
                            </div>
                        </Col>
                        <Col md={4}>
                            <div>
                                <img src='/images/prod_right.png' />
                                {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                </p> */}
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className='prod_left2'>
                                <img src='./images/prod_left2.jpg' />
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className='prod_center2'>
                                <img src='./images/prod_right_3.jpg' />
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className='prod_right2'>
                                <img src='./images/prod_right2.jpg' />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default Production