import React from 'react'
import { Container } from 'react-bootstrap';
import './OurStars.css';

const OurStar = () => {
    return (
        <Container>
            <div className='stars_div' id='cast'>
                <h1> OUR <span> STARS </span> </h1>

                <div className='star_main'>
                    <div>
                        <div>
                            <img src='/images/star1.png' />
                            <h6>SHAMOON ABBASI</h6>
                        </div>
                    </div>
                    <div className='star7_div'>
                        <div>
                            <img src='/images/star7.jpg' />
                            <h6>AYESHA OMER</h6>
                        </div>
                    </div>
                    <div>
                        <div className='humaiyon_div'>
                            <img src='/images/humaiyon.png' />
                            <h6>HUMAYOON ASHRAF</h6>
                        </div>
                    </div>
                </div>

                <div className='star_main'>
                    <div className='rashid_naz'>
                        <div>
                            <img src='/images/rashid-naz2.png' />
                            <h6>RASHID NAZ</h6>
                        </div>
                    </div>
                    <div className='saleem'>
                        <div>
                            <img src='/images/saleem-mairaj.jpg' />
                            <h6>SALEEM MAIRAJ</h6>
                        </div>
                    </div>
                    <div className='adnan_shah'>
                        <div>
                            <img src='/images/tipu.png' />
                            <h6>ADNAN SHAH TIPU</h6>
                        </div>
                    </div>
                </div>

                <div className='star_main'>
                    <div className='areej_chaud'>
                        <div>
                            <img src='/images/star2.png' />
                            <h6>AREEJ CH</h6>
                        </div>
                    </div>
                    <div className='star6_div'>
                        <div>
                            <img src='/images/star6.png' />
                            <h6>TAQI AHMED</h6>
                        </div>
                    </div>
                    <div className='star5_div'>
                        <div>
                            <img src='/images/farraz_marri.jpg' />
                            <h6>FARAZ MARRI</h6>
                        </div>
                    </div>

                </div>

                <div className='star_main'>
                    <div className='anya_khan'>
                        <div>
                            <img src='/images/anya.jpg' />
                            <h6>ANYA HASAN</h6>
                        </div>
                    </div>
                    <div className='pakiza'>
                        <div>
                            <img src='/images/pakiza_khan.jpg' />
                            <h6>PAKIZA KHAN</h6>
                        </div>
                    </div>
                    <div className='dummy_side'>
                        <div>
                            <img src='/images/waqas_ahmed.jpg' />
                            <h6>WAQAS KHAN</h6>
                        </div>
                    </div>
                    <div className='dummy_side'>
                        <div>
                            <img src='/images/asim_shah.jpg' />
                            <h6>ASIM SHAH</h6>
                        </div>
                    </div>
                </div>

                <h1 className='work_together'>
                    Let's Make
                    Great Work
                    Together
                </h1>
            </div>
        </Container>
    )
}

export default OurStar