import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './Vision.css';

const Vision = () => {
    return (
        <div className='vision_main'>
            <Container>
                <Row className='align-items-center'>
                    <Col md={5}>
                        <div className='vision_left'>
                            <img src='/images/vision_left.png' />
                        </div>
                    </Col>
                    <Col md={7}>
                        <div className='our_vision_right'>
                            <h1><span> SYNOPSIS </span></h1>
                            <p>The film revolves around Balochistan and terrosion activities of Indian spy
                                Kulbhushan yadav. Movie is highlighting the struggle of people of
                                balochistan against Indian sponsored terrorism.</p>
                            <img src='/images/vision_right.png' />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Vision