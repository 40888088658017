import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './Crew.css';

const Crew = () => {
    return (
        <div className='crew_main' id='crew'>
            <h1> <span> CREW </span> </h1>

            <Container>
                <Row>
                    <Col md={3} className='mb-3'>
                        <div className='crew_div'>
                            <img src='/images/crew3.png' />
                            <h4>D.O.P</h4>
                            <h5>IMRAN LEE</h5>
                        </div>
                    </Col>
                    <Col md={3} className='mb-3'>
                        <div className='crew_div'>
                            <img src='/images/asst_producer.png' />
                            <h4>ASSOCIATE PRODUCER</h4>
                            <h5>FAJJAR MINHAS</h5>
                        </div>
                    </Col>
                    <Col md={3} className='mb-3'>
                        <div className='crew_div'>
                            <img src='/images/crew2.png' />
                            <h4>ASSOCIATE DIRECTOR</h4>
                            <h5>SAQIB ZAFAR</h5>
                        </div>
                    </Col>
                    <Col md={3} className='mb-3'>
                        <div className='crew_div'>
                            <img src='/images/jamal_gilani.jpg' />
                            <h4>ART DIRECTOR</h4>
                            <h5>JAMAL GILANI</h5>
                        </div>
                    </Col>
                    <Col md={3} className='mb-3'>
                        <div className='crew_div'>
                            <img src='/images/cast4.png' />
                            <h4>PRODUCTION DESIGNER</h4>
                            <h5>MIRZA OWAIS BAIG</h5>
                        </div>
                    </Col>
                    <Col md={3} className='mb-3'>
                        <div className='crew_div'>
                            <img src='/images/tayyab2.JPG' />
                            <h4>CUSTOME DESIGNER</h4>
                            <h5>TAYYAB BOMBAL</h5>
                        </div>
                    </Col>
                    <Col md={3} className='mb-3'>
                        <div className='crew_div'>
                            <img src='/images/crew1.png' />
                            <h4>CUSTOME DESIGNER</h4>
                            <h5>KASHIF KHAN</h5>
                        </div>
                    </Col>
                    <Col md={3} className='mb-3'>
                        <div className='crew_div'>
                            <img src='/images/issac_solemon.jpg' />
                            <h4>EDITOR</h4>
                            <h5>ISSAC SOLEMON</h5>
                        </div>
                    </Col>
                    <Col md={3} className='mb-3'>
                        <div className='crew_div'>
                            <img src='/images/majid_makeup.jpg' />
                            <h4>MAKEUP ARTIST</h4>
                            <h5>MAJID HUSSAIN</h5>
                        </div>
                    </Col>
                    <Col md={3} className='mb-3'>
                        <div className='crew_div'>
                            <img src='/images/saboor_khan.jpg' />
                            <h4>MUSIC</h4>
                            <h5>SABOOR KHAN</h5>
                        </div>
                    </Col>
                    <Col md={3} className='mb-3'>
                        <div className='crew_div'>
                            <img src='/images/mohsin.jpg' />
                            <h4>SOUND TRACKS</h4>
                            <h5>MOHSIN MOAZZAM</h5>
                        </div>
                    </Col>
                    <Col md={3} className='mb-3'>
                        <div className='crew_div'>
                            <img src='/images/studio.jpg' style={{ objectFit: "contain" }} />
                            <h4>POST PRODUCTION</h4>
                            <h5>STUDIO 24/35</h5>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Crew